import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/services/services.module.scss';

class Contents extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const headingContents = document.querySelector('.init_a_headingContents');
    const introServices = document.querySelector('.init_a_introServices');
    setTimeout(() => {
      this.initAnimation.heading(headingContents);
      this.initAnimation.intro(introServices);
    }, 1000);
  }

  serviceList = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const serviceItem = document.querySelectorAll('.init_a_serviceItem');
      this.initAnimation.list(serviceItem);
      unobserve();
    }
  };

  serviceSkill = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const strategySkill = document.querySelector('.init_a_serviceSkill');
      this.initAnimation.list(strategySkill);
      unobserve();
    }
  };

  render() {
    const ContentsQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/services_contents.png" }){
          childImageSharp {
            fluid(maxWidth: 703, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        badge: file(relativePath: { eq: "illustration/icon_contents.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const optionsSkill = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={ContentsQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="services" />
              <ParallaxProvider>
                <main className={`services ${Style.services}`}>
                  <section className={`top ${Style.top}`}>
                    <div className={Style.left}>
                      <h1 className="heading">
                        <span className="text">Contents</span>
                        <span className="clip clipContents init_a_headingContents">Contents</span>
                      </h1>
                      <p className={`intro init_a_introServices ${Style.intro}`}>編集という職能を独自に定義し、良質なコンテンツを制作します。ユーザーにとって、本質的に価値のある情報とは何か？ メディア運営や取材経験の豊富な編集者が、テキストや映像を駆使し、印象に強く残る体験を届けます。</p>
                    </div>
                    <Parallax className={`${Style.illustration} ${Style.illustrationContents}`} x={[-5, 5]} tagInner="figure">
                      <Img
                        className={Style.illustrationImg}
                        fluid={data.illustration.childImageSharp.fluid}
                        alt="Contents"
                      />
                    </Parallax>
                  </section>
                  <Observer {...options} onChange={this.serviceList}>
                    <section className={`sectionContent sectionContentFirst ${Style.service}`}>
                      <div className="scroll scrollTop" />
                      <div className={`inner ${Style.inner}`}>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            京都を軸とした
                            <br />
                            コンテンツ領域
                          </dt>
                          <dd className={Style.serviceItemDescription}>拠点とする京都のアドバンテージを活かした、歴史や文化、観光領域のコンテンツ制作を得意としています。また、実際に住んでいるからこそわかる地域の特性や課題を把握した上で、京都というエリアにフォーカスしたメディア制作の実績が豊富です。地域に根ざし、事業活動の文脈理解を重視しています。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            コンテンツ
                            <br />
                            マーケティング
                          </dt>
                          <dd className={Style.serviceItemDescription}>コンテンツファーストによるビジネスの課題解決を得意としています。ソーシャルメディアの浸透などメディア環境が大きく変容し、生活者側が情報を選ぶ時代です。従来型の企業メッセージや広告施策の効果が低下するなかで、コンテンツを通じて、事業やサービスに対しての共感や自分ごと化を促します。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            文字・写真・映像
                            <br />
                            多様なメディア領域
                          </dt>
                          <dd className={Style.serviceItemDescription}>テキスト作成に加え、写真や映像の撮影・編集も一貫して行える総合的なコンテンツスタジオです。運用する媒体の特性に応じたコンテンツ・アプローチを提案しています。また、一次情報やストーリーテリングを重視し、読む人・見る人に強く訴えかけ、記憶に残るコンテンツの制作に力を入れています。</dd>
                        </dl>
                      </div>
                    </section>
                  </Observer>
                  <Observer {...optionsSkill} onChange={this.serviceSkill}>
                    <section className={`sectionContent ${Style.skillup}`}>
                      <div className={`inner init_a_serviceSkill ${Style.inner}`}>
                        <div className={Style.skillupIllustration}>
                          <Img
                            className={Style.illustrationImg}
                            fluid={data.badge.childImageSharp.fluid}
                            alt="Mediagrowth"
                          />
                        </div>
                        <div className={Style.right}>
                          <h4>メディア全体のコンサルティング</h4>
                          <p>メディア・広告広報業務のコンサルも対応可能です。編集の重要なスキルのひとつに、「情報の価値判断」があります。事業やサービスの背景にある「価値」を掘り起こしたり、優先順位をつけることで、発信すべき情報を価値づけます。</p>
                        </div>
                      </div>
                    </section>
                  </Observer>
                </main>
              </ParallaxProvider>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Contents;
